/*
 * @Author: wangqs 
 * @description 新建合同
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2021-09-22 17:28:04
 */
<template>
  <div class="page-box box">
    <!-- top box -->
    <div class="top-box box">
      <div class="title-box box">
        <img
          class="icon"
          src="@/assets/img/contract-icon-a.png"
          alt=""
        >
        分期业务合同
      </div>
      <div class="contract-btn box">
        <van-row
          type="flex"
          justify="space-between"
        >
          <van-col
            class="contract-left"
            span="12"
          >
            《融e学(商家版)分期业务合同》
          </van-col>
          <van-col
            v-if="info.sign == 0"
            class="contract-right"
            span="6"
            @click="goSign"
          >
            去签署
            <van-icon name="arrow" />
          </van-col>
          <van-col
            v-else
            class="contract-right contract-right-end"
            span="6"
          >
            签署完成
            <van-icon name="checked" />
          </van-col>
        </van-row>
      </div>
    </div>

    <!-- content-box -->
    <div class="content-box box">
      <div class="title-box box">
        <img
          class="icon"
          src="@/assets/img/contract-icon-b.png"
          alt=""
        >
        担保人合同
      </div>
      <div class="select-box box">
        <van-button
          plain
          class="item-btn"
          color="#51B464"
          :icon="require('@/assets/img/contract-icon-c.png')"
          @click="goGuarantee('person')"
        >
          个人担保
        </van-button>
        <van-button
          plain
          class="item-btn"
          color="#D5423E"
          :icon="require('@/assets/img/contract-icon-d.png')"
          @click="goGuarantee('org')"
        >
          机构担保
        </van-button>
      </div>
      <!-- 担保项 -->
      <template v-for="(item, index) in info.guaranteeList">
        <div
          :key="index"
          class="contract-item box"
        >
          <div
            v-if="item.sign == 0"
            class="contract-btns box"
          >
            <van-tag
              round
              class="tag-item"
              color="#E63035"
              @click="deleteGuarantee(item)"
            >
              删除
            </van-tag>
            <van-tag
              round
              class="tag-item"
              color="#3A80F7"
              @click="signBtn(item)"
            >
              签署
            </van-tag>
          </div>
          <div
            v-if="item.type == 1"
            class="contract-name"
          >
            <img
              class="name-img"
              :src="require('@/assets/img/contract-icon-c.png')"
              alt=""
            >
            担保人：{{ item.name || '--' }}
          </div>
          <div
            v-if="item.type == 2"
            class="contract-name"
          >
            <img
              class="name-img"
              :src="require('@/assets/img/contract-icon-d.png')"
              alt=""
            >
            担保机构：{{ item.name || '--' }}
          </div>
        
          <div
            class="box"
            style="margin-top: 15px;"
          >
            <van-row
              type="flex"
              justify="space-between"
            >
              <van-col
                class="contract-time-no"
                span="9"
              >
                签订日期:{{ (item.signDate && item.signDate.substring(0, 10)) || '--' }}
              </van-col>
              <van-col
                class="contract-time-no"
                span="15"
                style="text-align:right;"
              >
                合同编号:{{ item.contractNo || '--' }}
              </van-col>
            </van-row>
          </div>
        </div>
      </template>
    </div>

    <!-- 下一步 -->
    <van-button
      class="next-btn"
      color="#51B464"
      :disabled="isDisabled"
      round
      type="info"
      @click="nextFun"
    >
      下一步
    </van-button>

    <!-- code 码 -->
    <van-overlay
      style="background: rgba(0,0,0,0.3)"
      :show="show"
      @click="show = false"
    >
      <div
        class="wrapper-box"
        @click.stop="show = false"
      >
        <div
          class="block-box"
          @click.stop
        >
          <div class="text-a">
            请填写手机短信验证码
          </div>
          <div class="text-b">
            已发送到{{ activeData.mobile || '' }}
          </div>
          <van-field
            v-model="code"
            maxlength="6"
            class="text-c"
            placeholder="输入验证码"
            label="验证码"
          >
            <template #button>
              <span
                class="hr-btn"
                @click="sendCode"
              >{{ codeStatus ? '发送验证码' : codeTime }}</span>
            </template>
          </van-field>
          <van-button
            class="code-btn"
            color="#51B464"
            round
            type="info"
            @click="confirm"
          >
            提交
          </van-button>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: 'AddContract',
  components: {
  },
  data () {
    return {
      info: {},
      show: false,
      code: '',
      activeData: {},
      codeTime: 60,
      codeStatus: true
    }
  },
  computed: {
    // 下一步是否禁用
    isDisabled () {
      if (this.info.sign == 1 && this.info.guaranteeList && this.info.guaranteeList.length > 0) {
        let flag = false
        for (let i of this.info.guaranteeList) {
          if (i.sign == 0) {
            flag = true
          }
        }
        return flag
      }
      return true
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    async nextFun () {
      if (!this.isDisabled) {
        // 下一步
        let res = await this.$http.orgApi.orgSignFinish({id: this.$route.query.id})
        if (res.code === 200) {
          this.$toast.success('签署完成')
          setTimeout(() =>{
            this.$router.replace('/org/order')
          }, 1500)
        }
      }
    },
    async getDetail () {
      let res = await this.$http.orgApi.orderOrgInfo({id: this.$route.query.id})
      if (res.code == 200) {
        console.log(res, 'res')
        this.info = res.msg || {}
      }
    },
    // 去签署
    goSign () {
      let data = this.info
      data.orderNo = this.$route.query.id
      window.localStorage.setItem('pdfOrder', JSON.stringify(data))
      this.$router.push('/order/sign?type=orgPeriod&id=' + this.$route.query.id)
    },
    goGuarantee (msg) {
      if (this.info.sign == 0) {
       this.$toast('请先签署合同')
      } else if (msg === 'person') {
        this.$router.push('/guarantee/person?id=' + this.$route.query.id)
      } else if (msg === 'org') {
        this.$router.push('/guarantee/org?id=' + this.$route.query.id)
      }
    },
    // 删除
    deleteGuarantee (item) {
      console.log(item, 'item')
      this.$dialog.confirm({
        title: '删除担保',
        message: `确认删除${item.type == 1 ? '担保人' : '担保机构'}: ${item.name}？`,
      })
      .then(() => {
        this.$http.orgApi.orgGuaranteeRemove({id: item.id, orderNo: this.$route.query.id}).then((res) => {
          if (res.code === 200) {
            this.$toast.success('删除成功')
            this.getDetail()
          }
        })
      })
    },
    async signBtn (item) {
      this.activeData = item
      // this.show = true
      window.localStorage.setItem('pdfOrder', JSON.stringify(item))
      this.$router.push('/order/sign?type=contract&id=' + item.id + '&orderNo=' + this.$route.query.id)
    },
    
    sendCodeTime () {
      if (this.codeTime > 0) {
        this.codeTime --
        setTimeout(() => {
          this.sendCodeTime()
        }, 1000);
      } else {
        this.codeTime = 60
        this.codeStatus = true
      }
    },
    async sendCode () {
      if (!this.codeStatus) {
       return   
      }
      let res = await this.$http.orgApi.contractSmsSend({
        orderNo: this.$route.query.id,
        id: this.activeData.id
      })
      if (res.code === 200) {
        this.$toast.success('发送成功')
        this.codeStatus = false
        this.sendCodeTime()
      }
    },
    async confirm () {
      if (!this.code) {
        this.$toast('请填写验证码')
        return
      }
      this.$toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      });
      let data = {
        orderNo: this.$route.query.id || '',
        smsCode: this.code,
        id: this.activeData.id
      }
      let url = 'contractConfirm'
      let res = await this.$http['orgApi'][url](data)
      if (res.code === 200) {
        this.$toast.clear()
        this.$toast('签署成功')
        this.getDetail()
        this.code = ''
        this.show = false
        this.codeTime = 60
        this.codeStatus = true
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  background: #f5f5f5;
  .top-box {
    background: #fff;
    padding: 20px 15px 25px;
    .contract-btn {
      padding: 20px;
      line-height: 38px;
      background-color: #f8f9f9;
      margin-top: 28px;
      .contract-left {
        font-size: 14px;
        float: left;
        width: 70%;
      }
      .contract-right {
        font-size: 13px;
        color: #477BFF;
        width: 30%;
        text-align: right;
      }
      .contract-right-end {
        color: #51B464;
      }
    }
  }
  .title-box{
    font-size: 15px;
    padding-left: 26px;
    line-height: 18px;
    font-weight: bold;
    .icon {
      width: 18px;
      height: 18px;
      position: absolute;
      left: 0px;
      top: 0;
    }
  }
  .content-box {
    padding: 16px 15px;
    margin-top: 10px;
    background-color: #fff;
    .select-box {
      margin: 22px 0;
      text-align: center;
      .item-btn {
        display: inline-block;
        border-radius: 6px;
        height: 36px;
        line-height: 36px;
        margin: 0 20px;
      }
    }
    .contract-item {
      padding: 10px;
      background: #f8f8f9;
      margin-bottom: 12px;
      .contract-btns {
        text-align: right;
        .tag-item {
          padding-left: 4px;
          padding-right: 4px;
          margin-left: 5px;
        }
      }
      .contract-name {
        line-height: 18px;
        font-size: 14px;
        position: relative;
        overflow: hidden;
        padding-left: 26px;
        .name-img {
          width: 18px;
          height: 18px;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      .contract-time-no {
        font-size: 12px;
        color: #646464;
      }
    }
  }
  .next-btn {
    width: 345px;
    margin: 44px auto 0;
    display: block;
  }
}
</style>
